<template>
  <b-col
    offset-lg="3"
    lg="6"
  >
    <h3 class="text-center mb-5">
      {{ $t('tutorials') }}
    </h3>
    <div
      class="accordion"
      role="tablist"
    >
      <template v-for="(video, index) in videos">
        <b-card
          :key="index"
          no-body
          class="mb-1"
          :class="[
            { isMobile }
          ]"
        >
          <b-card-header
            v-b-toggle="'accordion-' + index"
            header-tag="header"
            class="p-1 hubu"
            role="tab"
          >
            {{ titles[index].content }}
          </b-card-header>
          <b-collapse
            :id="'accordion-' + index"
            :visible="index==0"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text>
                <iframe
                  width="100%"
                  height="315"
                  :src="'https://www.youtube.com/embed/' + video.content"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </template>
    </div>
  </b-col>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "Tutorials",

  computed: {
    isMobile () {
      return Cookies.get("mobile") === "false" ? false : true;
    },
    titles () {
      return this.$store.getters.getAppTexts.video_titles;
    },
    videos () {
      return this.$store.getters.getAppTexts.videos;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.hubu {
  background-color: $red;
  color: $white;
  text-align: left;
  padding-left: 20px !important;
  font-size: 1.15vw;
  cursor: pointer;
}

.isMobile {

  .hubu{
    font-size: 4vw !important;
  }
}
</style>